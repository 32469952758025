
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  name: 'Users',
  data () {
    return {
      addUsers: [],
      delUsers: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        username: '',
        roles: [],
        password: ''
      },
      switchRemoveMode: false,
      loading: false
    }
  },
  computed: {
    headersDelUsers() {
      return [
        { text: this.$t('users.table_col_username'), value: 'username' },
        { text: this.$t('users.table_col_roles'), value: 'roles', sortable: false}
      ]
    },
    headersAddUsers() {
      return [
        { text: this.$t('users.table_col_username'), value: 'username' },
        { text: this.$t('users.table_col_roles'), value: 'roles', width: "240px", sortable: false},
        { text: this.$t('users.table_col_default_password'), value: 'password', sortable: false},
        { text: this.$t('users.table_col_actions'), value: 'actions', sortable: false , width: "120px"}
      ]
    },
    defaultItem () {
      return {
        id: 0,
        username: `${this.addUsers.length + this.users.length + 1}@${this.clientName}`,
        roles: ['user'],
        password: '123456'
      }
    },
    roles () {
      if (this.userGroup == 'tenant') {
        return ['user', 'guest']
      } else if (this.userGroup == 'owner') {
        return ['user', 'guest', 'tenant']
      } else {
        return []
      }
    },
    ...mapState({
      users: (state: any) => state.users.users,
      clientName: (state: any) => state.auth.client.clientName,
      userGroup: (state: any) => state.auth.userGroup
    }),
  },
  methods: {
    removeNew (item) {
      this.addUsers.splice(this.addUsers.indexOf(item), 1)

      console.log(JSON.stringify(item))
      console.log(JSON.stringify(this.addUsers))
    },
    editItem (item) {
      this.editedIndex = this.addUsers.indexOf(item)
      this.editedItem = Object.assign({}, item)
    },
    appendNew () {
      const addObj = Object.assign({}, this.defaultItem)
      addObj.id = this.addUsers.length + 1
      this.addUsers.unshift(addObj)
      this.editItem(addObj)
    },
    close () {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 100)
    },
    confirmNew () {
      
      if (this.editedIndex > -1) {
        Object.assign(this.addUsers[this.editedIndex], this.editedItem)
      }
      this.close()
    },
    async saveExisting () {

      this.loading = true

      const rtnDeleteUsers = await this.deleteUsers(this.delUsers)

      await this.listUsers()

      this.loading = false

    },
    async saveNew () {

      this.loading = true

      const rtnCreateUsers = await this.createUsers(this.addUsers)

      console.log('rtnCreateUsers', rtnCreateUsers)

      this.addUsers = []

      await this.listUsers()

      this.loading = false

    },
    ...mapActions({
      createUsers: 'users/createUsers',
      deleteUsers: 'users/deleteUsers',
      listUsers: 'users/listUsers'
    })
  },
  async beforeRouteEnter (to, from, next) {

    let error = await store.dispatch('users/listUsers').catch(err => {
      return err
    })

    if (error) {
      console.log(error)
      router.push({name: 'Logout'})
    } else {
      next()
    }
  },
})
